import drawing from '../../../assets/img-1.svg'
import drawing2 from '../../../assets/img-2.svg'
import drawing3 from '../../../assets/img-3.svg'
import drawing4 from '../../../assets/img-4.svg'

import drawing5 from '../../../assets/Frame 6b.png'
import drawing6 from '../../../assets/Frame 5b.png'



export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Platform',
    headline: 'Leverage Our Tools & Expand Your Business',
    description: 'Manage existing clients, discover new partners and streamline documentation.',
    buttonLabel: 'Get started',
    imgStart: true,
    img: drawing5,
    alt: 'SVG',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Unlimited Access',
    headline: 'Access to our verified logistic network',
    description: 'Connect with verified 3PL providers.',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: drawing6,
    alt: 'SVG',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjThree = {
    id: 'services',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Platform',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Access to our exclusive app that allows you to send unlimited transactions.',
    buttonLabel: 'Get started',
    imgStart: true,
    img: drawing3,
    alt: 'SVG',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjFour = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Sign Up',
    headline: 'Get Started Today!',
    description: 'Reduce lead times and simplify your sefood trading business.',
    buttonLabel: 'Sign Up',
    imgStart: false,
    img: drawing4,
    alt: 'SVG',
    dark: false,
    primary: false,
    darkText: true
};