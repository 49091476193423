import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {FaTimes} from 'react-icons/fa'

export const Container = styled.div`
min-height: 692px;
position: fixed;
bottom: 0;
right: 0;
left: 0;
top: 0;
z-index: 0;
overflow: hidden;
background: linear-gradient(
    108deg,
    rgba(2, 107, 251, 1) 0%,
    rgba(0, 101, 122, 1) 100%
);
background: #010101;
`

export const CloseIcon = styled(FaTimes)`
color: #fff
`
export const IconWrap = styled(Link)`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;
`

export const FormWrap = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;

@media screen and (max-width: 400px){
    height: 80%;
}
`

export const Icon = styled(Link)`
margin-left: 32px;
margin-right: 32px;
text-decoration: none;
color: #fff;
font-weight: 700;
font-size: 32px;

@media screen and (max-width: 480px){
    margin-left: 16px;
    margin-top: 8px;
}
`

export const FormContent = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;

@media screen and (max-width: 480px){
    padding: 10px;
}
`

export const Form = styled.form`
backdrop-filter: blur(35px); 
background-color: rgba(255,255,255,0);

// background: #010101;
max-width: 800px;
height: auto;
width: 100%
z-index: 1;
display: grid;
margin: 0 auto;
padding: 80px 32px;
border-radius: 4px;
// box-shadow: 0 1px 3px rgba(0,0,0,0.5);

@media screen and (max-width: 400px){
    padding: 32px 32px;
}
`

export const FormH1 = styled.h1`
margin-bottom: 40px;
color: #fff;
font-size: 20px;
font-weight: 400;
text-align: center;
`

export const FormLabel = styled.label`
margin-bottom: 8px;
font-size: 14px;
color: #fff;
`
export const FormInput = styled.input`
padding: 16px 16px;
margin-bottom: 32px;
border: none;
border-radius: 4px;
`


export const FormButton = styled.button`
background: #026BFB;
padding: 16px 0;
border: none;
border-radius: 4px;
color: #fff;
font-size: 20px;
cursor: pointer;
`

export const Text = styled.span`
text-align: center;
margin-top: 24px;
color: #fff;
font-size: 14px;
`
