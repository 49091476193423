import React, {useState, useEffect} from 'react'
import './OrderActionShipments.scss'
import mapStyles from './mapStyles'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowRightAltTwoToneIcon from '@mui/icons-material/ArrowRightAltTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import IconLocation from '../../../assets/location-sign.svg'
import IconLocationOrigin from '../../../assets/warehouse-origin 2.png'
import IconLocationDestination from '../../../assets/warehouse-destination 2.png'

import PlaceHolderMap from '../../../assets/Placeholder_Map.png'


import {GoogleMap, useLoadScript, Marker, InfoWindow} from "@react-google-maps/api";
import { TextField } from '@mui/material';

function OrderActionShipments({order}) {
  const [ libraries ] = useState(['places']);
  const [selected, setSelected] = React.useState(null)

  const [markers, setMarkers] = React.useState([{lat: 39.316671, lng: -0.400038, style: "destination"}, { lat: 22.809543, lng: 69.694698, style: "origin" }]);
  const mapContainerStyle = {
    width: '30vw',
    height: '30vh'
  }
  const center = {
    lat: 20.809703,
    lng: 78.547250,
  }

  
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  }

  const portTo = { lat: 39.316671, lng: -0.400038 };
  const PortFrom = { lat: 22.809543, lng: 69.694698 }



  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error loading maps" ;
  if (!isLoaded) return "Loading Maps" ;
  return (
    <div className = 'trackingAction'>
      <p>{order.port_from}  <ArrowRightAltTwoToneIcon style={{paddingTop: "15px", width:"50px"}} />  {order.port_to}</p>
      <div className='trackTextBox'>
      Container Number: <br/><TextField id="outlined-basic" variant="outlined" placeholder={order.container_number} value={order.container_number} style={{paddingBottom:20}}/>
      <br/>Shipment Carrier: <br/><TextField id="outlined-basic" variant="outlined" placeholder="OOCL Line" value="OOCL Line"/><br/><br/>
      <button className = "trackOrder">Track</button>
      </div>

      {process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? ( <GoogleMap mapContainerStyle={mapContainerStyle} zoom={2} center={center} options={options} onLoad={onMapLoad}>
      
      {/* <Marker position={{ lat: portTo.lat, lng: portTo.lng }} icon={{url: IconLocation, scaledSize: new window.google.maps.Size(30,30), origin: new window.google.maps.Point(0,0), anchor: new window.google.maps.Point(15,15)}}/>

      <Marker position={{ lat: portTo.lat, lng: portTo.lng }} icon={{url: IconLocation, scaledSize: new window.google.maps.Size(30,30)}}/> */}

      { markers.map((marker) => (
        <>
        <p>{process.env.REACT_APP_TEST_HEROKU}</p>
          <Marker
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
              url: marker.style == "origin" ? IconLocationOrigin : IconLocationDestination,
              scaledSize: new window.google.maps.Size(40,40),
            }}
            onClick={() => {
              setSelected(marker)
              console.log(marker)
            }}
          />

          {selected ? (<InfoWindow position={{lat: selected.lat, lng: selected.lng}}>
            <div>
              <h2>{order.supplier}</h2>
              <p>Supplier Details</p>
            </div>
          </InfoWindow>) : null }
        </>
        ))}

      </GoogleMap>)
            :
      <img src = {PlaceHolderMap} width= "500"/>}
      <p>{order.order_status == 'completed' ? `Your order from ${order.port_from} to ${order.port_to} has been completed.` : null}</p>

      <p>Shipment Delivery Date: Delivered</p>
      <p>Document Delivery Date: Delivered</p>
      
    </div>
  )
}

export default OrderActionShipments