import React from 'react'
import "./NewOrderStep1.scss"

//Material UI Components 
import {Button,TextField,InputAdornment,SvgIcon, Table, TableBody} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';


import {useSelector} from 'react-redux';

function NewOrderStep1({handleSetContact, handleSetProduct, valueContact, valueProduct}) {
    const contacts = useSelector(state => state.contacts)
    const products = useSelector(state => state.products)


    //Contact Autofill Options
    const options = contacts.map((contact) => {
    const firstLetter = contact.company[0].toUpperCase();
    return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...contact,
    };
    });
    //Product Autofill Options
    const optionsProducts = products.map((product) => {
    const firstLetter = product.name[0].toUpperCase();
    return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...product,
    };
    });

    function handleContactChange(value){
        handleSetContact(value)
    }

    function handleProductChange(value){
        handleSetProduct(value)
    }

    
  return (
    <>
        <div className='step1-title'>
            <p className="modalInstruction"><b>Add some basic details to create your order.</b></p>
            <p className="modalInstructionUnderline"></p>
        </div>
        <div className='orderEntry'>
            <p className='detailTitle'><b>Contact</b></p>
                              
            <Autocomplete
            id="grouped-demo"
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.company}
            renderOption={(props, option) => {
                return (
                <li {...props} key={option.id}>
                    {option.company}
                </li>
                );
            }}
            sx={{ width: 300 }}

            renderInput={(params) => <TextField className = "contactInputBar" {...params} label={valueContact? valueContact.company: "Choose Contact..."} />}
            onChange={(event, value) => handleContactChange(value)}
            /> 
            <p className="detailAddNewText">Add New</p>
            <AddCircleIcon className="detailAddNewIcon"/> 
        </div>
        <div className='orderEntry'>
            <p className='detailTitle'><b>Product</b></p>
            <Autocomplete
            id="grouped-demo"
            options={optionsProducts.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(optionsProduct) => `${optionsProduct.name} ${optionsProduct.size}`}
            sx={{ width: 300 }}
            renderOption={(props, optionsProduct) => {
                return (
                <li {...props} key={optionsProduct.id}>
                    {optionsProduct.name} {optionsProduct.size}
                </li>
                );
            }}
            renderInput={(params) => <TextField className = "contactInputBar" {...params} label= {valueProduct? valueProduct.name: "Choose Product..."}  />}
            onChange={(event, value) => handleProductChange(value)}
            />                               
            <p className="detailAddNewText"> Add New</p>
            <AddCircleIcon className="detailAddNewIcon"/>
        </div>
    </>
    
  )
}

export default NewOrderStep1

