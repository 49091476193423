import React from 'react'
import { Paper, Container, Box, Typography, Stack, TableContainer, TextField } from '@mui/material'


function NewOrderStep3({valueProduct, valueContact, newOrder}) {
  return (
    <>        
    <Typography variant="subtitle2"><b>Contact:</b></Typography>
    <Typography variant="subtitle2">Company: {valueContact? `${valueContact.company}`: "None"}</Typography>
    <Typography variant="subtitle2">Reference: {valueContact? `${valueContact.first_name} ${valueContact.last_name}`: "None"}</Typography>
    <Typography variant="subtitle2">Location: {valueContact? `${valueContact.address_1}`: "None"}</Typography>
    <br/> 
    <Typography variant="subtitle2"><b>Product:</b></Typography> 
    <Typography variant="subtitle2">{valueProduct? valueProduct.name: "None"}</Typography> 
    <Typography variant="subtitle2">Size: {newOrder? newOrder.size: "None"}</Typography> 
    <Typography variant="subtitle2">Glaze: {newOrder? newOrder.glaze: "None"}</Typography> 
    <Typography variant="subtitle2">Freeze: {newOrder? newOrder.selected_freeze: "None"}</Typography> 
    <Typography variant="subtitle2">Packing: {newOrder? newOrder.packing: "None"}</Typography> 
    <Typography variant="subtitle2">Price per Kg: ${newOrder? newOrder.price_kg: "None"}</Typography> 



      
    </>
  )
}

export default NewOrderStep3