import React, {useEffect, useState} from 'react'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import OrderActionContainer from '../../components/Order/Single Order View/OrderActionContainer'
import OrderDetails from '../../components/Order/Single Order View/OrderDetails'
import OrderProgress from '../../components/Order/Single Order View/OrderProgress'
import './SingleOrderView.scss'
import OrderActionDocuments from '../../components/Order/Single Order View/OrderActionDocuments';
import OrderActionMessages from '../../components/Order/Single Order View/OrderActionMessages';
import OrderActionTasks from '../../components/Order/Single Order View/OrderActionTasks';
import OrderActionShipments from '../../components/Order/Single Order View/OrderActionShipments';

//MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AppsIcon from '@mui/icons-material/Apps';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//Redux//
import {useSelector, useDispatch} from 'react-redux';
import {loadOrders, deleteOrder, addOrder} from "../../components/Redux/Actions/orders"
import {loadContacts} from "../../components/Redux/Actions/contacts"
import {loadProducts} from "../../components/Redux/Actions/products"

//React Router//
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';


function SingleOrderView({order, handleGetOrder}) {

    const tabs = [
        "Documents", 
        "Messages", 
        "Tasks", 
        "Tracking", 
        "Photographs"
    ];

    //Redux States
    const loggedIn = useSelector(state => state.sessions.loggedIn)
    const currentUser = useSelector(state => state.sessions.currentUser)
    const { paramsId } = useParams();
    const dispatch = useDispatch()

    //React and React-Router
    const navigate = useNavigate()

    // useEffect(() => {
    //     if(loggedIn) {
            
    //         handleGetOrder(paramsId)
    //     } else {
    //         navigate("/login")
    //     }
    //     }, [loggedIn])

    

    //Local State
    const [activeStep, setActiveStep] = useState(
        localStorage.getItem("step") ? Number(localStorage.getItem("step")) : 0
      );

    //MultiStep Form on New Order Modal
    const handleChange = (index) => (e) => {
    setActiveStep(index);
    localStorage.setItem("step", index);
    };
  
  return (
    <>
        <div className='SOVContainer'>
            <div className='SOVFlexItem SOVFlexItem-1'>
                
                {/* <div className="left">
                    <OrderDetails/>
                </div>
                <div className="right">
                    <OrderProgress/>
                </div> */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={6}>
                        <Grid item xs={9}>
                        <Paper elevation={0} style={{padding: 32, minHeight:600}} variant="outlined"><OrderDetails order={order}/></Paper>
                        </Grid>
                        <Grid item xs={3}>
                        <Paper elevation={0} style={{padding: 32, minHeight:600}} variant="outlined"><OrderProgress order={order}/></Paper>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <div className='SOVFlexItem SOVFlexItem-2'>
                <OrderActionContainer tabs={tabs} activeStep={activeStep} handleChange={handleChange}/>  
                {(() => {
                switch (activeStep) {
                    case 0:
                        return <OrderActionDocuments order={order}/>
                    case 1:
                        return <OrderActionMessages/>
                    case 2:
                        return <OrderActionTasks/>
                    case 3:
                        return <OrderActionShipments order={order}/>
                  default:
                    return null
                }
              })()}   
            </div>

            <div className='timelineOveride'>


    </div>
            
        </div>
    </>
    
  )
}

export default SingleOrderView