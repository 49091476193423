import React, {useEffect, useState} from 'react'
import {animateScroll as scroll} from 'react-scroll'


//Project Components
import './Navbar.scss'
import Image from '../../assets/FinalLogo2.png'
import AvatarImg from '../../assets/Avatar.png'

//Material UI Components 
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import CssBaseline from '@mui/material/CssBaseline';


import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';


//Redux
import {useSelector, useDispatch} from 'react-redux';
import { logout } from '../Redux/Actions/sessions';
import Dashboard_Sidebar from '../Dashboard/Dashboard_Sidebar/DashboardSidebar';

function Navbar2({toggleSideBar}) {
    //REDUX
    const loggedIn = useSelector(state => state.sessions.loggedIn)
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.sessions.currentUser)
    
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);

    //Landing Page Scroll
    const [scrollNav, setScrollNav] = useState(false)

    function changeNav(){
    if(window.scrollY >= 200){
        setScrollNav(true);
    }else{ 
        setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
      }, []);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        //console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleLogout(e){
        e.preventDefault()
        handleClose()
        dispatch(logout())
        // logOutUser()
    }
    
    function handleClick2(){
        console.log('hey')
        toggleSideBar()
      }

      function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

      function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }
    
      function toggleHome(){
        scroll.scrollToTop()
      }

      const drawerWidth = 240;

      const AvatarInitials = currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : "Sumehr Gwalani"



      const theme = useTheme();
      const [open, setOpen] = React.useState(false);
    
      const handleDrawerOpen = () => {
        setOpen(!open);
      };


      const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
      })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }));

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" className='navbar' open={open} elevation={0}>
            <Toolbar className='navbar' disableGutters={true}>   
                {!open ? (
                <IconButton  size="large" edge="start" color="inherit" aria-label="menu"  onClick={handleDrawerOpen} >
                    <MenuIcon className = "navMenu"/>
                </IconButton>
                ) : null
                }
                <img className='logo' src={Image}/>
                <Typography variant="h6" component="div" className = "logoName">
                    withthe<b>tide</b>
                </Typography>
                

                <div className='navbarRight'>
                    <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={4} color="error" className = "navIcons">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                        <Badge badgeContent={17} color="error" className = "navIcons">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                        {/* <AccountCircle /> */}
                        {/* <Avatar alt="Remy Sharp" src={AvatarImg} className = "navIcons"/> */}
                        <Avatar alt="Remy Sharp" className = "navIcons" {...stringAvatar(AvatarInitials)} />
                    </IconButton>
                    <button className='NavBtnLink' onClick={handleLogout}>Logout</button>
                    {/* <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right',  }} open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>Account</MenuItem>
                        <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu> */}
                </div>
                
            </Toolbar>
        </AppBar>

        <Dashboard_Sidebar open= {open} handleDrawerOpen={handleDrawerOpen} />

        {/* <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer> */}
    </Box>
  )
}

export default Navbar2