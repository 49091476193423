import { headers, getToken } from '../../Globals'

export const loadContacts = () => {
    return async dispatch => {
        dispatch({type: "REQUESTING"})
        const resp = await fetch( 'api/contacts', {
            headers: {
              ...headers,
              ...getToken()
            }
          })

          const data = await resp.json()
    
          dispatch({type: "SET_CONTACTS", payload: data})
          dispatch({type: "DONE_REQUESTING"})
    }
}

export const addContact = (strongParams, navigate ) => {
  return async (dispatch) => {
      dispatch({type : "REQUESTING"});

      const resp = await fetch('api/contacts',{
          method: "POST",
      headers: {
          ...headers,
          ...getToken()
      },
      body: JSON.stringify(strongParams)
      })
      const data = await resp.json();
      console.log(data)
      if(data.errors) {
          dispatch({ type: "ERRORS", payload: data.errors })
        } else {

          dispatch({ type: "CLEAR_ERRORS" })
          dispatch({type: "ADD_CONTACT", payload: data})
        }
        dispatch({ type: "DONE_REQUESTING" });
  }
}



export const deleteContact = (navigate, id ) => {
  //***for fetch***//
  return async (dispatch) => {
      dispatch({type : "REQUESTING"});

      const resp = await fetch(`api/contacts/${id}`, {
        method: "DELETE",
        headers: {
          ...headers,
          ...getToken()
        }}).then((r) => {
          if (r.ok) {
            dispatch({type: "DELETE_CONTACT", payload: id})
          }
        });
        dispatch({ type: "DONE_REQUESTING" });
  }
}