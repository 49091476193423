import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SetMealIcon from '@mui/icons-material/SetMeal';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import HomeIcon from '@mui/icons-material/Home';
import "./DashboardSidebar.scss"
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import LogoutIcon from '@mui/icons-material/Logout';
import { Tooltip } from '@mui/material';
import { Navigate, Link } from 'react-router-dom';

function Dashboard_Sidebar({handleGetOrders, handleGetInquiries, handleGetProducts, open, handleDrawerOpen}) {
    const drawerWidth = 240;

    const openedMixin = (theme) => ({
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    });
    
    const closedMixin = (theme) => ({
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    });
      
      const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        height: 75,
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      }));

      // const AppBar = styled(MuiAppBar, {
      //   shouldForwardProp: (prop) => prop !== 'open',
      // })(({ theme, open }) => ({
      //   zIndex: theme.zIndex.drawer + 1,
      //   transition: theme.transitions.create(['width', 'margin'], {
      //     easing: theme.transitions.easing.sharp,
      //     duration: theme.transitions.duration.leavingScreen,
      //   }),
      //   ...(open && {
      //     marginLeft: drawerWidth,
      //     width: `calc(100% - ${drawerWidth}px)`,
      //     transition: theme.transitions.create(['width', 'margin'], {
      //       easing: theme.transitions.easing.sharp,
      //       duration: theme.transitions.duration.enteringScreen,
      //     }),
      //   }),
      // }));

      const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          }),
        }),
      );

      const theme = useTheme();


  function handleSidebarClick(text){
    console.log(text) 
  }
  function handleOrderClick(e){
    handleGetOrders(e)
  }
  function handleInquiryClick(e){
    handleGetInquiries(e)
  }
  function handleProductClick(e){
    handleGetProducts(e)
  }

  function closeDrawer(){
    handleDrawerOpen()
  }

  

  

  return (
    <>
    
      <Drawer variant="permanent" open={open} className ="sidebar" >
        <DrawerHeader >
          {open ? (
          <IconButton onClick ={closeDrawer}>
            {theme.direction === 'rtl' ? <ChevronRightIcon  /> : <ChevronLeftIcon  />}
          </IconButton>) : (
            <IconButton >
            {theme.direction === 'rtl' ? <MenuIcon /> : <MenuIcon />}
          </IconButton>
          )}  
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Dashboard" disablePadding sx={{ display: 'block' }}>
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }} >
              <Tooltip title="Dashboard">
              <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}} >
                <Link to={`/dashboard`} style={{ textDecoration: "none" }} className="links">
                  <HomeIcon  color="action"/>
                </Link>
              </ListItemIcon>
              </Tooltip>
            
              <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />

            </ListItemButton>
          </ListItem>
        </List>

        <Divider />
        
        <List>
          <ListItem key={'Orders'} disablePadding sx={{ display: 'block' }} >
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} >
                <Tooltip title={"Orders"}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }} >
                    <Link to={`/orders`} style={{ textDecoration: "none" }} className="links">
                      <SummarizeIcon color="action"/>
                    </Link>
                  </ListItemIcon>
                </Tooltip>
              <ListItemText primary={'Orders'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem key={'Inquiries'} disablePadding sx={{ display: 'block' }} >
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                <Tooltip title={"Inquiries"}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                    <Link to={`/inquiries`} style={{ textDecoration: "none" }} className="links" >
                      <ForwardToInboxIcon color="action"/>
                    </Link>
                  </ListItemIcon>
                </Tooltip>
              <ListItemText primary={'Inquiries'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem key={'Shipments'} disablePadding sx={{ display: 'block' }} >
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                <Tooltip title={"Shipments"}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                    <Link to={`/shipments`} style={{ textDecoration: "none" }} className="links" >
                    < DirectionsBoatFilledIcon color="action"/>
                    </Link>
                  </ListItemIcon>
                </Tooltip>
              <ListItemText primary={'Shipments'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem key={'Contacts'} disablePadding sx={{ display: 'block' }} >
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                <Tooltip title={"Contacts"}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                    <Link to={`/contacts`} style={{ textDecoration: "none" }} className="links" >
                    <ContactPageIcon color="action"/>
                    </Link>
                  </ListItemIcon>
                </Tooltip>
              <ListItemText primary={'Contacts'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem key={'Products'} disablePadding sx={{ display: 'block' }} >
            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                <Tooltip title={"Products"}>
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                    <Link to={`/products`} style={{ textDecoration: "none" }} className="links" >
                    <SetMealIcon color="action"/>
                    </Link>
                  </ListItemIcon>
                </Tooltip>
              <ListItemText primary={'Products'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
            
        </List>
        <Divider />

        <Divider />
        
        <List>
            
          {[ 'Profile', "Messages", 'Notifications', 'Settings'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <Tooltip title={text}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Link to={`/messages`} style={{ textDecoration: "none" }} className="links" >
                  {index === 0 ? <AccountCircleIcon  color="action"/> : index === 1 ?<MailIcon color="action"/> : index === 2?  <NotificationsIcon color="action"/> : <SettingsIcon color="action"/>}
                  </Link>
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Divider />

        <List>
            <ListItem key="Logout" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <Tooltip title="Logout">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>    
        </List>

        
        
      </Drawer>
      
        <DrawerHeader />
      
    
    </>
  );


}

export default Dashboard_Sidebar