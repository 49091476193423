import React, {useEffect, useState} from 'react';

//React Router//
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Navbar from './components/Navigation/Navbar';

//Redux//
import {useSelector, useDispatch} from 'react-redux'
import { getCurrentUser } from './components/Redux/Actions/sessions';

//Components//
import LandingPage from './pages/LandingPage/LandingPage';
import Signup from './components/Authentication/Signup';
import Login from './components/Authentication/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import { headers, getToken } from './components/Globals' 
import Inquiries from './pages/Inquiries/Inquiries';
import OrdersList from './pages/Orders/OrdersList';
import SingleOrder from './pages/Orders/SingleOrder';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Contacts from './pages/Contacts/Contacts';
import Products from './pages/Products/Products';
import Shipments from './pages/Shipments/Shipments';
import Navbar2 from './components/Navigation/Navbar2';
import Footer from './components/Footer/Footer';
import Messages from './pages/Messages/Messages';
import Orders from './pages/Orders/Orders';
import DashboardHome from './pages/Dashboard/DashboardHome';
import SingleOrderView from './pages/Orders/SingleOrderView';

function App() {
  //Local States
  const [isOpen, setIsOpen] = useState(false)
  const [items, setItems] = useState([])
  const [order, setOrder] = useState([])

  //Redux
  const loggedIn = useSelector(state => state.sessions.loggedIn)
  const requesting = useSelector(state=>state.requesting)
  const dispatch = useDispatch()

  //Redux States
  useEffect(()=>{
    dispatch(getCurrentUser())
  },[loggedIn])


  function toggleSideBar(e){
    setIsOpen(!isOpen)
  }

  function handleGetProducts(e){
    fetch('/items', {
      headers: {
        ...headers,
        ...getToken()
      }
    })
    .then(resp => resp.json())
    .then(data => {
      console.log(data)
      setItems(data)
  })
  }

  function handleGetOrder(order_id){
    fetch(`api/orders/${order_id}`, {
      headers: {
        ...headers,
        ...getToken()
      }
    })
    .then(resp => resp.json())
    .then(data => {
      //console.log(data)
      setOrder(data)     
  })}
  
  return (
    <div className="App">
      <Router>
        {loggedIn ? (<Navbar2 toggleSideBar={toggleSideBar}/>) : (<Navbar toggleSideBar={toggleSideBar}/>)}
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/inquiries" element={<Inquiries/>}/>
          <Route path="/contacts" element={<Contacts/>}/>
          <Route path="/products" element={<Products/>}/>
          <Route path="/messages" element={<Messages/>}/>
          <Route path="/shipments" element={<Shipments/>}/>
          <Route path="/orders" element={<Orders handleGetOrder={handleGetOrder}/>}/>
          <Route path="/orders/:id" element={<SingleOrderView  order={order}  handleGetOrder={handleGetOrder}/>} />
          <Route path="/profile" element={<ProfilePage/>}/>
          <Route path="/dashboard" element={<DashboardHome handleGetProducts={handleGetProducts} handleGetOrder={handleGetOrder}/>}/>
        </Routes>
        <Footer/>
      </Router>
    
      
    </div>
  );
}

export default App;
