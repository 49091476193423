import React, {useEffect, useState} from 'react';

import Dashboard_Sidebar from '../../components/Dashboard/Dashboard_Sidebar/DashboardSidebar'
import ScrollToTop from '../../components/ScrollToTop'
import AvatarImg from '../../assets/Avatar.png'
import "./ProfilePage.scss";

//Redux//
import {useSelector, useDispatch} from 'react-redux';

//React Router//
import { useNavigate } from 'react-router-dom'

//Material UI//
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';


function ProfilePage() {
  //Local state
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Redux State
  const loggedIn = useSelector(state => state.sessions.loggedIn)
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.sessions.currentUser)

  //React and React-Router
  const navigate = useNavigate()
  
  useEffect(() => {
    if(loggedIn) {
      console.log("HEY")
    } else {
      navigate("/login")
    }
  }, [loggedIn])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px"
  };
    
  
  return (
    <>
        {/* <ScrollToTop/> */}
        <div className="single">
            <Dashboard_Sidebar/>
            <div className="singleContainer">
                <br/>
                <br/>
                <br/>
                <div className="top">
                <div className="left">
                
                  <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Profile
                      </Typography>
                      {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                      </Typography> */}
                      <TextField id="standard-required" label="Name"  variant="filled">{currentUser.first_name}</TextField>
                      <TextField id="standard-required" label="Company" defaultValue="Company" variant="filled"/>
                      <TextField id="standard-required" label="Phone" defaultValue="Phone" variant="filled"/>
                      <TextField id="standard-required" label="Email" defaultValue="Email" variant="filled"/>
                      <TextField id="standard-required" label="Address" defaultValue="Address" variant="filled"/>
                    </Box>
                  </Modal>
                    <div className="editButton" onClick={handleOpen}>Edit Profile</div>
                    <h1 className="title">My Information</h1>
                    <div className="item">
                    <img
                        src={AvatarImg}
                        alt=""
                        className="itemImg"
                    />
                    <div className="details">
                        <h1 className="itemTitle">{currentUser.first_name} {currentUser.last_name}</h1>
                        <div className="detailItem">
                            
                        <div className="detailItem">
                        <span className="itemKey">Company:</span>
                        <span className="itemValue">{currentUser.company}</span>
                        </div>

                        <span className="itemKey">Email:</span>
                        <span className="itemValue">{currentUser.email ? currentUser.email : "Add Email"}</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Phone:</span>
                        <span className="itemValue">Add Phone Number</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Address:</span>
                        <span className="itemValue">
                            Add Address
                        </span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Country:</span>
                        <span className="itemValue">Add Country</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Profile Picture:</span>
                        <span className="itemValue">Upload
                          <IconButton color="primary" aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" />
                            <PhotoCamera />
                          </IconButton>
                        </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="right">
                </div>
                </div>
            </div>
        </div>
  
    </>
  )
}

export default ProfilePage