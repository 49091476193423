//Components Imports
import DashboardCharts from '../../components/Dashboard/Dashboard_Charts/DashboardCharts'
import DashboardInquiry from '../../components/Dashboard/Dashboard_Inquiry/DashboardInquiry'
import Dashboard_Sidebar from '../../components/Dashboard/Dashboard_Sidebar/DashboardSidebar'
import DashboardTable from '../../components/Dashboard/Dashboard_Table/DashboardTable'
import DashboardWidgets from '../../components/Dashboard/Dashboard_Widgets/DashboardWidgets'
import Footer from '../../components/Footer/Footer'
import "./DashboardHome.scss"

//Redux Imports
import {useSelector, useDispatch} from 'react-redux';
import {loadInquiries} from "../../components/Redux/Actions/inquiries"
import { getCurrentUser } from '../../components/Redux/Actions/sessions';

//React and Router-Dom Imports
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'

import { Paper, Container, Box, Typography, Stack, TableContainer } from '@mui/material'
import OrderTableList from '../../components/Order/Order Table List/OrderTableList'



function DashboardHome() {
    //Redux States
    const loggedIn = useSelector(state => state.sessions.loggedIn)
    const orders = useSelector(state => state.orders)
    const contacts = useSelector(state => state.contacts)
    const products = useSelector(state => state.products)
    const currentUser = useSelector(state => state.sessions.currentUser)

  return (
    <>
        <div className='dashboardContainer'>
            <div className='dashboardFlexibleItem dashboardFlexibleItem-1'>
            <Typography variant="h5" >Welcome, {currentUser.first_name}</Typography>
            </div>

            <div className='dashboardFlexibleItem dashboardFlexibleItem-2'>
                <DashboardWidgets type = "active_orders"/>
                <DashboardWidgets type = "completed_orders"/>
                <DashboardWidgets type = "inquiries"/>
                <DashboardWidgets type = "contact"/>
                <DashboardWidgets type = "product"/>
            </div>

            <div className='dashboardFlexibleItem dashboardFlexibleItem-3'>
                <OrderTableList/>
            </div>

            <div className='dashboardFlexibleItem dashboardFlexibleItem-4'>
                <DashboardInquiry />
                <DashboardCharts title="Fluctations in price of Baby Squid (100/UP)" aspect={2 / 1}/>
            </div>
        </div>
    </>
  )
}

export default DashboardHome