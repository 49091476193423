import React from 'react'

//Components
import { homeObjOne, homeObjTwo, homeObjFour } from '../../components/LandingPage/InfoSection/Data';
import InfoSection from '../../components/LandingPage/InfoSection/InfoSection';
import LandingSection from '../../components/LandingPage/LandingSection/LandingSection';
import Services from '../../components/LandingPage/Services/Services';
import Footer from '../../components/Footer/Footer';

export default function LandingPage() {


  return (
    <div>
        <LandingSection/>
        <InfoSection {...homeObjOne }/>
        <InfoSection {...homeObjTwo }/>
        <Services/>
        <InfoSection {...homeObjFour }/>
    </div>
  )
}