import React, {useEffect, useState} from 'react'
import "./OrderTableList.scss"

import missingOrderImage from '../../../assets/img-6.svg'

//Material UI Components 
import { Paper, Container, Box, Typography, Stack, TableContainer } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import {Button,TextField,InputAdornment,SvgIcon, Table, TableBody} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';


//Redux//
import {useSelector, useDispatch} from 'react-redux';
import {loadOrders, deleteOrder, addOrder} from "../../Redux/Actions/orders"
import {loadContacts} from "../../Redux/Actions/contacts"
import {loadProducts} from "../../Redux/Actions/products"

//React Router//
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

//Modal Components
import NewOrderStep1 from '../New Order Modal/NewOrderStep1';
import NewOrderStep2 from '../New Order Modal/NewOrderStep2';
import NewOrderStep3 from '../New Order Modal/NewOrderStep3';
import NewOrderStepsHeader from '../New Order Modal/NewOrderStepsHeader';

function OrderTableList({handleGetOrder}) {
    //Local States
    const [status, setStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('')  
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [activeStep, setActiveStep] = useState(
      localStorage.getItem("step") ? Number(localStorage.getItem("step")) : 0
    );

    //New Order Modal
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    //MultiStep Form on New Order Modal
    const handleChange = (index) => (e) => {
      setActiveStep(index);
      localStorage.setItem("step", index);
    };

    const handleNext = () => {
      setActiveStep(activeStep + 1);
      localStorage.setItem("step", activeStep + 1);
    };
    const handlePrev = () => {
      setActiveStep(activeStep - 1);
      localStorage.setItem("step", activeStep - 1);
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      const strongParams = {
        order: {
          po_number: newOrder.po_number,
          order_status: "registered",
          po_date: newOrder.po_date,
          internal_ref_number: newOrder.internal_ref_number,
          order_item_id: newOrder.order_item_id,
          size: newOrder.size,
          glaze: newOrder.glaze,
          price_kg: newOrder.price_kg,
          carton: newOrder.carton,
          packing: newOrder.packing,
          kgs_carton: newOrder.kgs_carton,
          packing_specs: newOrder.packing_specs,
          shipment_date:newOrder.shipment_date,
          payment_terms: newOrder.payment_terms,
          port_to: newOrder.port_to,
          port_from: newOrder.port_from,
          order_total: newOrder.order_total,
          selected_freeze: newOrder.selected_freeze,
          total_kilos: newOrder.total_kilos,
          contact_id: valueContact.id,
          user_id: currentUser.id,
          task: newOrder.task
        }
    }

    dispatch(addOrder(navigate, strongParams))



  setNewOrder({
    po_number: null,
    order_status: "registered",
    po_date: null,
    internal_ref_number: null,
    order_item_id: null,
    size: null,
    glaze: null,
    price_kg: null,
    carton: null,
    packing: null,
    kgs_carton: null,
    packing_specs: null,
    shipment_date:null,
    payment_terms: null,
    port_to: null,
    port_from: null,
    order_total: null,
    selected_freeze: null,
    total_kilos: null,
    contact_id: valueContact.id,
    user_id: currentUser.id,
    task: null
})

      localStorage.removeItem("step");
      setFormSubmitted(true);
      const data = Array.from(e.target.elements)
        .map((el) => el.id)
        .filter(Boolean)
        .reduce((accObj, field) => {
          accObj[field] = e.target.elements[field].value;
          return accObj;
        }, {});
      alert(JSON.stringify(data, null, 2));
    };

    const tabs = ["Base Details", "Specifications", "Review"];

    //Order Actions
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    //Redux States
    const loggedIn = useSelector(state => state.sessions.loggedIn)
    const orders = useSelector(state => state.orders)
    const contacts = useSelector(state => state.contacts)
    const products = useSelector(state => state.products)
    const currentUser = useSelector(state => state.sessions.currentUser)

    const slicedOrders = orders.slice(0, 5);



    const dispatch = useDispatch()

    //React and React-Router
    const navigate = useNavigate()

    useEffect(() => {
      if(loggedIn) {
          dispatch(loadContacts())
          dispatch(loadOrders())
          dispatch(loadProducts())
      } else {
          navigate("/login")
      }
      }, [loggedIn])

    //Order Table Columns
    const columns = [
      {
        id: 'status',
        label: 'Status',
        minWidth: 450,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'details',
        label: 'Details',
        minWidth: 400,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'buyer',
        label: 'Buyer',
        minWidth: 200,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'seller',
        label: 'Seller',
        minWidth: 200,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'shipment_date',
        label: 'Shipment Date',
        minWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
      },
      {
        id: 'options',
        label: '',
        minWidth: 10,
        align: 'center',
        format: (value) => value.toFixed(2),
      },
    ];

    //More Options Table Row Button
    const menuOptions = [
      'View',
      'Edit',
      'Reorder',
      'Delete'
    ];
    
    //Contact Autofill Options
    const options = contacts.map((contact) => {
      const firstLetter = contact.company[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...contact,
      };
    });
    //Product Autofill Options
    const optionsProducts = products.map((product) => {
      const firstLetter = product.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...product,
      };
    });

    //Modal Inputs
    const [valueContact, setValueContact] = React.useState(options[0]);     
    const [valueProduct, setValueProduct] = React.useState(optionsProducts[0]);

    function handleSetProduct(product){
      console.log(product)
      setValueProduct(product)
    }

    function handleSetContact(contact){
      setValueContact(contact)
    }

    const ITEM_HEIGHT = 48;

    const [newOrder, setNewOrder] = useState({
      po_number: null,
      order_status: "registered",
      po_date: null,
      internal_ref_number: null,
      order_item_id: null,
      size: null,
      glaze: null,
      price_kg: null,
      carton: null,
      packing: null,
      kgs_carton: null,
      packing_specs: null,
      shipment_date:null,
      payment_terms: null,
      port_to: null,
      port_from: null,
      order_total: null,
      selected_freeze: null,
      total_kilos: null,
      contact_id: null,
      user_id: currentUser.id,
      task: null
  })

  function handleFormData(e){
    const name = e.target.name
    const value = e.target.value

    setNewOrder({
        ...newOrder,
        [name] : value
    })
  }

  function handleSingleOrderClick(order_id){
    handleGetOrder(order_id)  
  }

  



  
  return (
    <div className='flexible-item-dashboard'>
            <Paper elevation={0} sx={{ borderRadius: 0, }}>
            <TableContainer sx={{ maxHeight: 1000 }}>
        {orders.length > 0 ? (
        <Table stickyHeader aria-label="sticky table">
          <TableHead className = "tableHead"  sx={{
            "& th": {
              
              fontSize: 16,
            }
          }} >
            <TableRow>
              {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} ><b>{column.label}</b></TableCell>
              ))}
            </TableRow>
          </TableHead>
          
                <TableBody>
                  {slicedOrders.filter((order) => {
                    if (searchTerm == ""){
                      return order
                    } else if (order.product.name.toLowerCase().includes(searchTerm.toLowerCase())){
                      return order
                    }
                  })
                  .map((order) => (
                  <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover className = "orderRow">
                    <TableCell align="center" component="th" scope="row" >
                    {/* <p className="statusBarText3">GI-1345-PO-7488</p> */}
                    <div className ="statusView">
                      <div className="progress">
                        
                      </div>
                      <div className="rectangleTableList"><div className='circle'></div></div>
                      <p className="statusBarText2">Status: {order.order_status}</p>
                      <p className="statusBarText"><b>PO-{order.po_number}</b> GI-{order.internal_ref_number}</p>
                    </div>
                      
                      
                    </TableCell>
                    <TableCell align="center">
                      <p className='orderCell'><b>{order.product_name}</b></p>
                      <p className='orderCell'>{order.size} - {order.total_kilos} Kgs @ ${order.price_kg} per Kg </p>
                    </TableCell>
                    <TableCell align="center" onClick={()=>{console.log(order)}}>{order.importer ? order.importer : order.contact.company}</TableCell>
                    <TableCell align="center" >{order.supplier? order.supplier : currentUser.company}</TableCell>
                    <TableCell align="center" >{order.shipment_date}</TableCell>

                    <TableCell>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch',
                          boxShadow: 'none',
                          backgroundColor: 'lightGrey'
                        },
                      }}
                    >
                      {menuOptions.map((option) => (
                        <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                    </TableCell>

                    {/* <TableCell align="center">
                      <Link  to={`/orders/${order.id}`} onClick = {() => handleSingleOrderClick(order.id)} variant="outlined" style={{paddingLeft: 13, textDecoration: 'none'}} ><Button variant="outlined" >View</Button></Link>
                    </TableCell> */}
    
                  </TableRow>
                  ))}
                </TableBody>
          </Table>
          ): (
            <div className='emptyOrders'>
              <img src = {missingOrderImage} width="100" height="100"/>
              
              <p>You do not have any orders yet.</p>
              <button className='firstOrder'><Link to="/orders" style={{ textDecoration: "none", color: "black" }}>New Order</Link></button>
              </div>
          )}
        </TableContainer>
            </Paper>
        </div>
   
  )
}

export default OrderTableList