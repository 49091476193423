import React from 'react'
import "./DashboardCharts.scss"
import {
    AreaChart,
    Area,
    XAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    LineChart, YAxis, Line
  } from "recharts";
  
  const data = [
    { name: "January", Total: 0.97 },
    { name: "February", Total: 0.97 },
    { name: "March", Total: 0.95 },
    { name: "April", Total: 0.9 },
    { name: "May", Total: 0.85 },
    { name: "June", Total: 1.25 },
  ];

function DashboardCharts({ aspect, title }) {
  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#8884d8"
            fillOpacity={0}
            fill="url(#total)"
          />
        </AreaChart>
          {/* <LineChart width={500} height={300} data={data.total}>
    <XAxis dataKey="name"/>
    <YAxis/>
    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
    <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
  </LineChart> */}
      </ResponsiveContainer>
    </div>
  )
}

export default DashboardCharts