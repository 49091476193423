import React from "react";

import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';

import "./NewOrderStepsHeader.scss"

const NewOrderStepsHeader = ({
  classes,
  activeStep,
  handleChange,
  tabs,
  formSubmitted
}) => {
  const tabWidth = 100 / tabs.length;
  const indicatorLeft = activeStep * tabWidth;
  const indicatorStyle = {
    width: `${tabWidth}%`,
    left: `${indicatorLeft}%`
  };

  return (
    <Grid container className="root">
      {/* {tabs.map((tab, index) => (
        <Grid item key={index} style={{ width: `${tabWidth}%` }}>
          <ButtonBase
            className="tab"
            onClick={handleChange(index)}
            disabled={formSubmitted}
          >
            {tab}
          </ButtonBase>
          
        </Grid>
      ))} */}
        <div className='orderStages'>
            <ul className="orderStageMenu">
            {tabs.map((tab, index) => (
                <li key = {index} onClick={handleChange(index)} className={`${index <= activeStep ? "activeStep" : "orderStageItem"}`}>{tab}</li>
                ))}
            </ul>
        </div>

      {/* <div style={indicatorStyle} className="indicator">
        <span>{tabs[activeStep]}</span>
      </div> */}
    </Grid>
  );
};
export default NewOrderStepsHeader;

{/* <li onClick={handleChange(index)} className='orderStageItem'>Specifications</li>
<li onClick={handleChange(index)} className='orderStageItem'>Review</li> */}