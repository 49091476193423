import React from 'react'

import './OrderDetails.scss'
//Material UI Components 
import { Paper, Container, Box, Typography, Stack, TableContainer, TableBody, TableRow, TableCell, Table } from '@mui/material'

function OrderDetails({order}) {
  return (
    <div className='detailsContainerSOV'>
      <div className="item">
          <div className="detailsSOV">
          
              <div className="detailHeaderSOV">
                <Typography variant="h5">Order Details - {order.product_name}</Typography>
                <button className = "editOrder" onClick={()=> console.log(order)} >Edit</button>
                <div className="detailItemSOV">
                  <span className="itemKey">Reference Numbers:  </span>
                  <span className="itemValue">  GI-{order.internal_ref_number}-PO-{order.po_number}</span>
                </div>
                
                <div className="detailItemSOV">
                  <span className="itemKey">Status:  </span>
                  <span className="itemValue">{order.order_status}</span>
                </div>
              </div>

              <div className='step1-titleSOV'>
                  <p className="modalInstructionSOV"><b>Order Specs</b></p>
                  <p className="modalInstructionUnderlineSOV"></p>
              </div>

              <div className="detailHeaderSOV">
              <Table stickyHeader aria-label="sticky table">
              <TableBody >
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Supplier:</p>
                    <p className='orderCellSOVValue'>{order.supplier}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Importer:</p>
                    <p className='orderCellSOVValue'>{order.importer}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Shipment Date:</p>
                    <p className='orderCellSOVValue'>{order.shipment_date}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Port to:</p>
                    <p className='orderCellSOVValue'>{order.port_to}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Port from:</p>
                    <p className='orderCellSOVValue'>{order.port_from}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Payment terms:</p>
                    <p className='orderCellSOVValue'>{order.payment_terms}</p>
                  </TableCell>
                  
                  
                </TableRow>
              </TableBody>
              </Table>
             
              </div>

              <div className='step1-titleSOV'>
                  <p className="modalInstructionSOV"><b>Product Specs</b></p>
                  <p className="modalInstructionUnderlineSOV"></p>
              </div>
              <TableBody >
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Product:</p>
                    <p className='orderCellSOVValue'>{order.product_name}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Size:</p>
                    <p className='orderCellSOVValue'>{order.size}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Glaze:</p>
                    <p className='orderCellSOVValue'>{order.glaze}%</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Price/kg:</p>
                    <p className='orderCellSOVValue'>${order.price_kg}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Order total:</p>
                    <p className='orderCellSOVValue'>${order.order_total}</p>
                  </TableCell>     
                </TableRow>

                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'></p>
                    <p className='orderCellSOVValue'></p>
                  </TableCell>  

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Total Kilos:</p>
                    <p className='orderCellSOVValue'>{order.total_kilos}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Kgs per Carton:</p>
                    <p className='orderCellSOVValue'>{order.kgs_carton}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Cartons:</p>
                    <p className='orderCellSOVValue'>{order.cartons}</p>
                  </TableCell>

                  <TableCell align="left" style={{borderBottom:"none"}}>
                    <p className='orderCellSOVKey'>Packing:</p>
                    <p className='orderCellSOVValue'>{order.packing} {order.packing_specs}</p>
                    
                  </TableCell>
   
                </TableRow>
              </TableBody>

          </div>
      </div>
    </div>
  )
}

export default OrderDetails