import React, {useEffect, useState} from 'react'
import "./Orders.scss"

import { headers, getToken } from '../../components/Globals'


import missingOrderImage from '../../assets/img-6.svg'

//Material UI Components 
import { Paper, Container, Box, Typography, Stack, TableContainer } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import {Button,TextField,InputAdornment,SvgIcon, Table, TableBody, Select} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';


//Redux//
import {useSelector, useDispatch} from 'react-redux';
import {loadOrders, deleteOrder, addOrder} from "../../components/Redux/Actions/orders"
import {loadContacts} from "../../components/Redux/Actions/contacts"
import {loadProducts} from "../../components/Redux/Actions/products"

//React Router//
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

//Modal Components
import NewOrderStep1 from '../../components/Order/New Order Modal/NewOrderStep1';
import NewOrderStep2 from '../../components/Order/New Order Modal/NewOrderStep2';
import NewOrderStep3 from '../../components/Order/New Order Modal/NewOrderStep3';
import NewOrderStepsHeader from '../../components/Order/New Order Modal/NewOrderStepsHeader';

function Orders({ handleGetOrder}) {
        //Local States
        const [status, setStatus] = useState('');
        const [searchTerm, setSearchTerm] = useState('')
        const [filterWord, setFilterWord] = useState('all')    
        const [formSubmitted, setFormSubmitted] = useState(false)
        const [activeStep, setActiveStep] = useState(
          localStorage.getItem("step") ? Number(localStorage.getItem("step")) : 0
        );

        const [CSV, setCSV] = useState({});

        //New Order Modal
        const [openModal, setOpenModal] = React.useState(false);
        const handleOpenModal = () => setOpenModal(true);
        const handleCloseModal = () => setOpenModal(false);

        const [openModalCSV, setOpenModalCSV] = React.useState(false);
        const handleOpenModalCSV = () => setOpenModalCSV(true);
        const handleCloseModalCSV = () => setOpenModalCSV(false);

        //MultiStep Form on New Order Modal
        const handleChange = (index) => (e) => {
          setActiveStep(index);
          localStorage.setItem("step", index);
        };

        const handleNext = () => {
          setActiveStep(activeStep + 1);
          localStorage.setItem("step", activeStep + 1);
        };
        const handlePrev = () => {
          setActiveStep(activeStep - 1);
          localStorage.setItem("step", activeStep - 1);
        };


        const handleSubmit = (e) => {
          e.preventDefault();



        const strongParams = {
          order: {
            po_number: 2753,
            order_status: "registered",
            po_date: newOrder.po_date,
            internal_ref_number: 1762,
            order_item_id: newOrder.order_item_id,
            size: newOrder.size,
            glaze: newOrder.glaze,
            price_kg: newOrder.price_kg,
            carton: newOrder.carton,
            packing: newOrder.packing,
            kgs_carton: newOrder.kgs_carton,
            packing_specs: newOrder.packing_specs,
            shipment_date: newOrder.shipment_date,
            payment_terms: newOrder.payment_terms,
            port_to: newOrder.port_to,
            port_from: newOrder.port_from,
            order_total: newOrder.order_total,
            selected_freeze: newOrder.selected_freeze,
            total_kilos: newOrder.total_kilos,
            contact_id: valueContact.id,
            user_id: currentUser.id,
            task: newOrder.task
          }
      }

        

        dispatch(addOrder(strongParams, navigate))



      setNewOrder({
        po_number: null,
        order_status: "registered",
        po_date: null,
        internal_ref_number: null,
        order_item_id: null,
        size: null,
        glaze: null,
        price_kg: null,
        cartons: null,
        packing: null,
        kgs_carton: null,
        packing_specs: null,
        shipment_date:null,
        payment_terms: null,
        port_to: null,
        port_from: null,
        order_total: null,
        selected_freeze: null,
        total_kilos: null,
        contact_id: valueContact.id,
        user_id: currentUser.id,
        task: null
    })

          localStorage.removeItem("step");
          setFormSubmitted(true);
          // const data = Array.from(e.target.elements)
          //   .map((el) => el.id)
          //   .filter(Boolean)
          //   .reduce((accObj, field) => {
          //     accObj[field] = e.target.elements[field].value;
          //     return accObj;
          //   }, {});
          // alert(JSON.stringify(data, null, 2));
        };

        const tabs = ["Base Details", "Specifications", "Review"];
  
        //Order Actions
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = (order_id) => {
          setAnchorEl(null);
        };

        //Redux States
        const loggedIn = useSelector(state => state.sessions.loggedIn)
        const orders = useSelector(state => state.orders)
        const contacts = useSelector(state => state.contacts)
        const products = useSelector(state => state.products)
        const currentUser = useSelector(state => state.sessions.currentUser)


        const dispatch = useDispatch()

        //React and React-Router
        const navigate = useNavigate()

        useEffect(() => {
          if(loggedIn) {
              dispatch(loadContacts())
              dispatch(loadOrders())
              dispatch(loadProducts())
          } else {
              navigate("/login")
          }
          }, [loggedIn])
  
        //Order Table Columns
        const columns = [
          {
            id: 'status',
            label: 'Status',
            minWidth: 450,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'details',
            label: 'Details',
            minWidth: 400,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'buyer',
            label: 'Buyer',
            minWidth: 200,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'seller',
            label: 'Seller',
            minWidth: 200,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'shipment_date',
            label: 'Shipment Date',
            minWidth: 200,
            align: 'center',
            format: (value) => value.toFixed(2),
          },
          {
            id: 'options',
            label: '',
            minWidth: 10,
            align: 'center',
            format: (value) => value.toFixed(2),
          },
        ];
    
        //More Options Table Row Button
        const menuOptions = [
          'View',
          'Edit',
          'Reorder',
          'Delete'
        ];
        
        //Contact Autofill Options
        const options = contacts.map((contact) => {
          const firstLetter = contact.company[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...contact,
          };
        });
        //Product Autofill Options
        const optionsProducts = products.map((product) => {
          const firstLetter = product.name[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...product,
          };
        });

        //Modal Inputs
        const [valueContact, setValueContact] = React.useState(options[0]);     
        const [valueProduct, setValueProduct] = React.useState(optionsProducts[0]);

        function handleSetProduct(product){
          
          setValueProduct(product)
        }

        function handleSetContact(contact){
          setValueContact(contact)
        }

        const ITEM_HEIGHT = 48;

        const [newOrder, setNewOrder] = useState({
          po_number: null,
          order_status: "registered",
          po_date: null,
          internal_ref_number: null,
          order_item_id: null,
          size: null,
          glaze: null,
          price_kg: null,
          carton: null,
          packing: null,
          kgs_carton: null,
          packing_specs: null,
          shipment_date:null,
          payment_terms: null,
          port_to: null,
          port_from: null,
          order_total: null,
          selected_freeze: null,
          total_kilos: null,
          contact_id: null,
          user_id: currentUser.id,
          task: null
      })

      function handleFormData(e){
        const name = e.target.name
        const value = e.target.value
    
        setNewOrder({
            ...newOrder,
            [name] : value
        })
      }

      function handleDelete(order_id){
        // console.log(inquiry_id)
        dispatch(deleteOrder(navigate, order_id))
      }
      const [age, setAge] = React.useState('');

      const handleChange2 = (event) => {
        setAge(event.target.value);
      };

      function handleUploadCSV(e){
        setCSV(e.target.files[0])
      }

      let formData = new FormData();
      formData.append('file', CSV);
      //formData.append('buys', buys)

      let optionsCSV = {
        method: 'POST',
        headers: {"Authorization": `bearer ${localStorage.getItem('jwt')}`},
        body: formData
        }

      function handleCSVSubmit(e){
        
        const resp = fetch(`api/orders/import`, optionsCSV)
            .then(resp => resp.json())
            .then(result => {
                
                
                dispatch(loadOrders())
                handleCloseModalCSV()
        })
      }

      function handleSingleOrderClick(order_id){
        handleGetOrder(order_id)  
      }

      function handleOrderFilter(e){
        //console.log(e.target.name)
        setFilterWord(e.target.name)
      }
      



  return (
    <>
    <div className='flexible-container'>
        <div className='flexible-item flexible-item-1'>
            <Paper elevation={0}>
                <div className="row">
                  <div className="column left">
                      <Typography variant="h5" >Orders</Typography>
                  </div>
                  <div className="column right">
                      <TextField fullWidth InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                              <SvgIcon color="action" fontSize="small" >
                                  <SearchIcon />
                              </SvgIcon>
                              </InputAdornment>
                          )
                          }}
                          placeholder="Search for Product"
                          variant="outlined"
                          onChange={event => setSearchTerm(event.target.value)}
                          className = "searchBar"
                          size ="small"
                          inputProps={{style: {fontSize: 15}}} // font size of input text
                          InputLabelProps={{style: {fontSize: 16}}}   
                      />
                    <button className = "newOrder" onClick={handleOpenModal}>New Order <span className='newOrderBadge'><AddCircleIcon/></span></button>
                    <button className = "newOrder" onClick={handleOpenModalCSV}>Upload CSV <span className='newOrderBadge'><AddCircleIcon/></span></button>
                 </div>
                </div>
            </Paper>
        </div>
        <div className='flexible-item flexible-item-2'>
            <Paper elevation={0} sx={{ borderRadius: 0, }}>
            <ul className="orderCategoryMenu">
                <li className='orderCategoryItem'><button className='orderCategoryButton' name = "all" onClick={(name) => handleOrderFilter(name)}>All Orders</button></li>
                <li className='orderCategoryItem'><button className='orderCategoryButton' name = "registered" onClick={(name) => handleOrderFilter(name)}>Registered</button></li>
                <li className='orderCategoryItem'><button className='orderCategoryButton' name = "shipped" onClick={(name) => handleOrderFilter(name)}>Shipped</button></li>
                <li className='orderCategoryItem'><button className='orderCategoryButton' name = "delivered" onClick={(name) => handleOrderFilter(name)}>Delivered</button></li>
                <li className='orderCategoryItem'><button className='orderCategoryButton' name = "completed" onClick={(name) => handleOrderFilter(name)}>Completed</button></li>
                <li className='orderCategoryItem'><button className='orderCategoryButton' name = "cancelled" onClick={(name) => handleOrderFilter(name)}>Cancelled</button></li>
            </ul>
            </Paper>
        </div>
        <div className='flexible-item flexible-item-3'>
            <Paper elevation={0} sx={{ borderRadius: 0, }}>
            <TableContainer sx={{ maxHeight: 1000 }}>
        {orders.length > 0 ? (
        <Table stickyHeader aria-label="sticky table">
          <TableHead className = "tableHead"  sx={{
            "& th": {
              
              fontSize: 16,
            }
          }} >
            <TableRow>
              {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} ><b>{column.label}</b></TableCell>
              ))}
            </TableRow>
          </TableHead>
          
                <TableBody>
                  {orders.filter((order) => {
                    if (searchTerm == ""){
                      return order
                    } else if (order.product_name.toLowerCase().includes(searchTerm.toLowerCase())){
                      return order
                    }
                  })
                  .filter((order) => {
                    if (filterWord == "all"){
                      return order
                    }
                    else if (order.order_status == filterWord){
                      return order
                    }
                  })
                  .map((order) => (
                  <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover className = "orderRow">
                    <TableCell align="center" component="th" scope="row" >
                    {/* <p className="statusBarText3">GI-1345-PO-7488</p> */}
                    <div className ="statusView">
                      <div className="progressOrders">
                        
                      </div>
                      <div className="rectangleOrders"><div className='circleOrders'></div></div>
                      <p className="statusBarText2">Status: {order.order_status}</p>
                      <p className="statusBarText"><b>PO-{order.po_number}</b> GI-{order.internal_ref_number}</p>
                    </div>
                      
                      
                    </TableCell>
                    <TableCell align="center">
                      {/* <p className='orderCell'><b>{order.order_item.species_name}</b></p> */}

                      <p className='orderCell'><b>{order.product_name}</b></p>
                      <p className='orderCell'>{order.size} - {order.total_kilos} Kgs @ ${order.price_kg} per Kg </p>
                      {/* <p className='orderCell'>80/ UP -  19440 Kgs @ $0.85..... </p> */}
                    </TableCell>
                    <TableCell align="center" >{order.importer ? order.importer : order.contact.company}</TableCell>
                    <TableCell align="center" >{order.supplier? order.supplier : currentUser.company}</TableCell>
                    <TableCell align="center" >{order.shipment_date}</TableCell>
                    <TableCell align="center">
                      <Link  to={`/orders/${order.id}`} onClick = {() => handleSingleOrderClick(order.id)} variant="outlined" style={{paddingLeft: 13, textDecoration: 'none'}} ><Button variant="outlined" >View</Button></Link>
                    </TableCell>
                    <TableCell>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch',
                          boxShadow: 'none',
                          backgroundColor: 'lightGrey'

                        },
                      }}
                    >
                      {/* {menuOptions.map((option) => (
                        <MenuItem key={option} selected={option === 'Pyxis'}  onClick={()=> handleClose(order.id)} >
                          {option}
                        </MenuItem>
                      ))} */}
                      <MenuItem value={10} >View</MenuItem>
                      <MenuItem value={20} >Edit</MenuItem>
                      <MenuItem value={30} >Delete</MenuItem>
                    </Menu>
                                        {/* <Select
                    IconComponent = {MoreVertIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange2}
                      >
                        <MenuItem value={10}>View</MenuItem>
                        <MenuItem value={20}>Edit</MenuItem>
                        <MenuItem value={30}>Delete</MenuItem>
                      </Select> */}
                    </TableCell>

                    
    
                  </TableRow>
                  ))}
                </TableBody>
          </Table>
          ): (
            <div className='emptyOrders'>
              <img src = {missingOrderImage} width="100" height="100"/>
              
              <p>You do not have any orders yet.</p>
              <button className='firstOrder' onClick={handleOpenModal}>New Order</button>
              </div>
          )}
        </TableContainer>
            </Paper>
        </div>
    </div>

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box className='NewOrderModal'>
        <div className='modalContainer'>
          <div className='modalItem'>
              <div className="columnModal leftModal">
                <Typography variant="h5" ><b>New Order</b></Typography>
              </div>
          <div className="columnModal rightModal">
            <button className = "closeModal" onClick= {handleCloseModal}><CancelIcon/></button>
            <Typography variant="subtitle2" className='reorder'>Import details from previous order?</Typography>
          </div>
        </div>
        <NewOrderStepsHeader tabs={tabs} activeStep={activeStep}handleChange={handleChange} formSubmitted={formSubmitted}/>
        <div className='modalItem'>
            <form className="formContainer">
              {(() => {
                switch (activeStep) {
                  case 0:
                    return <NewOrderStep1 handleSetContact={handleSetContact} handleSetProduct={handleSetProduct} valueProduct={valueProduct} valueContact={valueContact}/>
                  case 1:
                    return <NewOrderStep2 valueProduct={valueProduct} handleFormData={handleFormData} newOrder={newOrder}/>
                  case 2:
                    return <NewOrderStep3 valueProduct={valueProduct} valueContact={valueContact} newOrder={newOrder}/>
                  default:
                    return null
                }
              })()}
            </form>
        </div>
        
        <div className='orderEntry'>
        {activeStep === 0 ? <button className = "backButtonDisabled" disabled onClick={handlePrev}>Back</button> : <button className = "newOrder" onClick={handlePrev} >Back</button>}
        
        {activeStep == 0 || activeStep == 1 ? <button className = "newOrder" onClick={handleNext}>Next</button> : <button className = "newOrder" onClick={handleSubmit}>Submit</button>}
        </div>
        </div>
        </Box>
      </Fade>
    </Modal>

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalCSV}
      onClose={handleCloseModalCSV}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalCSV}>
        <Box className='NewOrderModalCSV'>
        <div className='modalContainerCSV'>
          <div className='modalItem'>
              <div className="columnModal leftModal">
                <Typography variant="h5" ><b>Upload Orders</b></Typography>
              </div>
          <div className="columnModal rightModal">
            <button className = "closeModal" onClick= {handleCloseModalCSV}><CancelIcon/></button>
          </div>
        </div>
        <div className='modalItemCSV'>
            <input className = "modalUploadCSVButton" type={"file"} accept={".csv"} onChange = {handleUploadCSV}/>
        </div>
        
        <div>
        {CSV ? <button className='submitCSV' onClick = {handleCSVSubmit}>Upload +</button>: null}
        </div>
        </div>
        </Box>
      </Fade>
    </Modal>


    </>
  )
}

export default Orders