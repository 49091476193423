import React, {useEffect, useState} from 'react'

//Material UI Icons//
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SetMealIcon from '@mui/icons-material/SetMeal';

//Material UI Components//
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Container, Box } from '@mui/material'
import {Button,Card,CardContent,TextField,InputAdornment,SvgIcon, Typography} from '@mui/material';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';

//Components//
import Footer from '../../components/Footer/Footer'
import Dashboard_Sidebar from '../../components/Dashboard/Dashboard_Sidebar/DashboardSidebar'

//React Router//
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

//Redux//
import {useSelector, useDispatch} from 'react-redux';
import {loadProducts} from "../../components/Redux/Actions/products"


function Products() {
        //Redux States
        const currentUser = useSelector(state => state.sessions.currentUser)
        const loggedIn = useSelector(state => state.sessions.loggedIn)
        let products = useSelector(state => state.products)
    
        const dispatch = useDispatch()

        
    //Local States
    const [status, setStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('')      
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [CSV, setCSV] = useState({});

    let formData = new FormData();
    formData.append('file', CSV);
    //formData.append('buys', buys)


    let options = {
        method: 'POST',
        headers: {"Authorization": `bearer ${localStorage.getItem('jwt')}`},
        body: formData
        }


    const [newContact, setNewContact] = useState({
        company: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address_1: "",
        address_2: "", 
        user_id: currentUser.id,
        notes: ""
      })

        //React and React-Router
    const navigate = useNavigate()

      useEffect(() => {
        if(loggedIn) {
            dispatch(loadProducts())
        } else {
            navigate("/login")
        }
        }, [loggedIn])

    function handleFormData(e){
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        // setNewContact({
        //     ...newContact,
        //     [name] : value
        // })
      }

      function handleSubmit(e){
        e.preventDefault();
        
        const strongParams = {
            contact: {
                company: newContact.company,
                first_name: newContact.first_name,
                last_name: newContact.last_name,
                email: newContact.email,
                phone: newContact.phone,
                address_1: newContact.address_1,
                address_2: newContact.address_2, 
                user_id: currentUser.id,
                notes: newContact.notes
            }
        } 
  
        // dispatch(addContact(strongParams, navigate))
  
        setNewContact({
            company: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            address_1: "",
            address_2: "", 
            user_id: currentUser.id,
            notes: ""
          })
      }

      function handleSingleOrderClick(order_id){
        console.log(order_id)  
      }

      function handleDelete(order_id){
        console.log(order_id)
        //dispatch(deleteOrder(navigate, order_id))
      }

      function handleCSVAdd(e){
        //console.log(e.target.files[0])
        setCSV(e.target.files[0])
      }

      function handleCSVSubmit(e){
        console.log(products)
        const resp = fetch(`api/products/import`, options)
            .then(resp => resp.json())
            .then(result => {
                console.log(result)
                dispatch(loadProducts())
                handleClose()
        })

        // fetch(baseUrl + '/orders',{
        //     method: "POST",
        //     headers: {
        //         ...headers,
        //         ...getToken()
        //     },
        //     body: JSON.stringify(strongParams)
        // })
        // .then(resp => resp.json())
        // .then(data => {
        //     handleGetOrders(e)
        //     navigate('/orders')
        // })
      }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: "10px"
      };

      const columns = [
        {
            id: 'name',
            label: 'Name',
            minWidth: 130,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'specification',
            label: 'Specification',
            minWidth: 130,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'size',
            label: 'Size',
            minWidth: 130,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        ];
    

  return (
    <>
        <Dashboard_Sidebar/>
        <Container style={{ minHeight: '100vh' }}>
        <br/>
        <br/>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                New Product
                </Typography>
                {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                </Typography> */}
                {/* <TextField id="standard-required" label="Company"  name="company" variant="filled" onChange={handleFormData}/>
                <TextField id="standard-required" label="Full Name"  name="first_name" variant="filled" onChange={handleFormData}/>
                <TextField id="standard-required" label="Phone"  name="phone" variant="filled" onChange={handleFormData}/>
                <TextField id="standard-required" label="Email"  name="email" variant="filled" onChange={handleFormData}/>
                <TextField id="standard-required" label="Address"  name="address" variant="filled" onChange={handleFormData}/> */}
                <br/>
                <input type={"file"} accept={".csv"} onChange = {handleCSVAdd}/>
                <br/>
                <br/>
                <Button color="primary" variant="contained" onClick = {handleCSVSubmit}>Upload CSV +</Button>
{/* 
                <Button color="primary" variant="contained" onClick = {handleSubmit}>Add +</Button> */}
            </Box>
        </Modal>
        <Box sx={{alignItems: 'center', display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap',m: -1}}>
            <Typography sx={{ m: 1 }} variant="h4">Products</Typography>
            <Box sx={{ m: 1 }}>
            {/* <Button startIcon={(<UploadIcon fontSize="small" />)} sx={{ mr: 1 }}>Import</Button>
            <Button startIcon={(<DownloadIcon fontSize="small" />)} sx={{ mr: 1 }} >Export</Button>
            <Button color="primary" variant="contained" onClick = {()=> navigate(`/new_order`)}>New Order</Button> */}
            <Button color="primary" variant="contained" onClick={handleOpen}>Product +</Button>
            </Box>
             </Box>
                <Box sx={{ mt: 3 }}>
                    <Card>
                    <CardContent>
                        <Box sx={{ maxWidth: 500 }}>
                        <TextField fullWidth InputProps={{ startAdornment: (
                                <InputAdornment position="start">
                                    <SvgIcon color="action" fontSize="small">
                                        <SearchIcon />
                                    </SvgIcon>
                                </InputAdornment>
                            )
                            }}
                            placeholder="Search product"
                            variant="outlined"
                            onChange={event => setSearchTerm(event.target.value)}/>
                        </Box>
                    </CardContent>
                    </Card>
                </Box>
            <br/>   
            {products.length > 0 ? (  
            <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation = {2}  padding = {4}>
                <TableContainer sx={{ maxHeight: 1000 }}>
                <Stack>
                    <br/>
                    <Box sx={{ minWidth: 120 }}>
                    
                    </Box>
                </Stack>

                
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                        <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>{column.label}</TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    
                    {products.filter((product) => {
                        if (searchTerm == ""){
                        return product
                        } else if (product.name.toLowerCase().includes(searchTerm.toLowerCase())){
                        return product
                        }
                    })
                    .map((product) => (
                    <TableRow key={product.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                        <TableCell align="center" >{product.name}</TableCell>
                        <TableCell align="center" >{product.specification}</TableCell>
                        <TableCell align="center" >{product.size}</TableCell>
                        {/* <TableCell align="center">
                        <Link  to={`/contacts/${product.id}`} onClick = {() => handleSingleOrderClick(product.id)} variant="outlined" style={{paddingLeft: 13, textDecoration: 'none'}} ><Button variant="outlined" >View</Button></Link>
                        </TableCell>
                        <TableCell align="center" onClick = {()=> handleDelete(product)}><Button variant="outlined" color="error">Delete</Button></TableCell> */}
                    </TableRow>
                    ))}
                    </TableBody>
                </Table>
                
                </TableContainer>
            </Paper>
            ): (
                <>
                <SetMealIcon/>
                <p>You have not created any products yet.</p>
                <Button color="primary" variant="contained" onClick = {handleOpen}>Product +</Button>
                </>
            ) }
        </Container>
        <br/>
        <br/>
        <br/>
        <Footer/>
    </>
  )
}

export default Products