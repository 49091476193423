import React, {useEffect, useState} from 'react'
import "./Shipments.scss"
import ImageLoading from "../../assets/img-5.svg"

//Material UI Components 
import { Paper, Container, Box, Typography, Stack, TableContainer } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import {Button,TextField,InputAdornment,SvgIcon, Table, TableBody} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import missingOrderImage from '../../assets/img-6.svg'
import IconButton from '@mui/material/IconButton';


function Shipments() {
        //Local States
        const [status, setStatus] = useState('');
        const [searchTerm, setSearchTerm] = useState('')  
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
    
        const columns = [
          {
            id: 'status',
            label: 'Status',
            minWidth: 450,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'details',
            label: 'Details',
            minWidth: 400,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'buyer',
            label: 'Buyer',
            minWidth: 200,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'seller',
            label: 'Seller',
            minWidth: 200,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
          },
          {
            id: 'shipment_date',
            label: 'Shipment Date',
            minWidth: 200,
            align: 'center',
            format: (value) => value.toFixed(2),
          },
          {
            id: 'options',
            label: '',
            minWidth: 10,
            align: 'center',
            format: (value) => value.toFixed(2),
          },
        ];
    
        const options = [
          'None',
          'Atria',
          'Callisto',
          'Dione',
          'Ganymede',
          'Hangouts Call',
          'Luna',
          'Oberon',
          'Phobos',
          'Pyxis',
          'Sedna',
          'Titania',
          'Triton',
          'Umbriel',
        ];
        
        const ITEM_HEIGHT = 48;
    
        const orders = [
          {
              "id": 1,
              "order_status": "registered",
              "po_date": null,
              "po_number": 1000,
              "internal_ref_number": null,
              "order_item_id": 1,
              "size": "20/40",
              "glaze": "10",
              "price_kg": 4,
              "cartons": 835,
              "packing": "6x1",
              "kgs_carton": 6,
              "packing_specs": "printed bags",
              "shipment_date": "2022-08-22",
              "payment_terms": "sight dp or lc at 75 days",
              "port_to": "Valencia, Spain",
              "port_from": "Pipavav, India",
              "order_total": 21292,
              "selected_freeze": null,
              "total_kilos": 5010,
              "buyer_id": 1,
              "seller_id": 3,
              "created_at": "2022-08-20T20:27:37.450Z",
              "updated_at": "2022-08-20T20:27:37.450Z",
              "order_item": {
                  "id": 1,
                  "species_name": "Whole Cleaned Cuttlefish",
                  "scientific_name": "Sepiida",
                  "selected_size": null,
                  "kilos": null,
                  "price_per_kg": null,
                  "price_total": null,
                  "selected_freeze": null,
                  "selected_glazecatching_method": null,
                  "created_at": "2022-08-20T20:27:37.409Z",
                  "updated_at": "2022-08-20T20:27:37.409Z"
              }
          },
          {
              "id": 2,
              "order_status": "registered",
              "po_date": null,
              "po_number": 1001,
              "internal_ref_number": null,
              "order_item_id": 1,
              "size": "80/20",
              "glaze": "20",
              "price_kg": 6,
              "cartons": 935,
              "packing": "6x1",
              "kgs_carton": 8,
              "packing_specs": "printed bags",
              "shipment_date": "2022-07-17",
              "payment_terms": "sight dp or lc at 75 days",
              "port_to": "Valencia, Spain",
              "port_from": "Pipavav, India",
              "order_total": 21292,
              "selected_freeze": null,
              "total_kilos": 5010,
              "buyer_id": 1,
              "seller_id": 2,
              "created_at": "2022-08-20T20:27:37.458Z",
              "updated_at": "2022-08-20T20:27:37.458Z",
              "order_item": {
                  "id": 1,
                  "species_name": "Whole Cleaned Cuttlefish",
                  "scientific_name": "Sepiida",
                  "selected_size": null,
                  "kilos": null,
                  "price_per_kg": null,
                  "price_total": null,
                  "selected_freeze": null,
                  "selected_glazecatching_method": null,
                  "created_at": "2022-08-20T20:27:37.409Z",
                  "updated_at": "2022-08-20T20:27:37.409Z"
              }
          },
          {
              "id": 3,
              "order_status": "registered",
              "po_date": null,
              "po_number": 1002,
              "internal_ref_number": null,
              "order_item_id": 1,
              "size": "80/20",
              "glaze": "20",
              "price_kg": 6,
              "cartons": 935,
              "packing": "6x1",
              "kgs_carton": 8,
              "packing_specs": "printed bags",
              "shipment_date": "2022-07-29",
              "payment_terms": "sight dp or lc at 75 days",
              "port_to": "Valencia, Spain",
              "port_from": "Pipavav, India",
              "order_total": 21292,
              "selected_freeze": null,
              "total_kilos": 5010,
              "buyer_id": 1,
              "seller_id": 2,
              "created_at": "2022-08-20T20:27:37.465Z",
              "updated_at": "2022-08-20T20:27:37.465Z",
              "order_item": {
                  "id": 1,
                  "species_name": "Whole Cleaned Cuttlefish",
                  "scientific_name": "Sepiida",
                  "selected_size": null,
                  "kilos": null,
                  "price_per_kg": null,
                  "price_total": null,
                  "selected_freeze": null,
                  "selected_glazecatching_method": null,
                  "created_at": "2022-08-20T20:27:37.409Z",
                  "updated_at": "2022-08-20T20:27:37.409Z"
              }
          }
      ]
  return (
    <>
    <div className='flexible-container'>
        <div className='flexible-item flexible-item-1'>
            <Paper elevation={0}>
                <div className="row">
                    <div className="column left">
                        <Typography variant="h5" >Shipments</Typography>
                    </div>
                    <div className="column right">
                        <button className = "newOrder">New Shipment <span className='newOrderBadge'><AddCircleIcon/></span></button>
                        <TextField fullWidth InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <SvgIcon color="action" fontSize="small" >
                                    <SearchIcon />
                                </SvgIcon>
                                </InputAdornment>
                            )
                            }}
                            placeholder="Search Shipments"
                            variant="outlined"
                            onChange={event => console.log(event.target.value)}
                            className = "searchBar"
                            size ="small"
                            inputProps={{style: {fontSize: 15}}} // font size of input text
                            InputLabelProps={{style: {fontSize: 16}}}   
                        />
                        
                    </div>
                </div>
            </Paper>
        </div>
        <div className='flexible-item flexible-item-2 emptyMessages'>
            <Paper elevation={0} sx={{ borderRadius: 0, }}>
                <br/>
                <h4>Shipment Management</h4>
                <p>Coming Soon!</p>
            <img src={ImageLoading} width="300" height="300"/>        
            </Paper>
        </div>

    </div>

    
    </>
  )
}

export default Shipments