import React from 'react'
import "./OrderProgress.scss"



//Material UI Components 
import { Paper, Container, Box, Typography, Stack, TableContainer } from '@mui/material'

//Material UI Icons
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AppsIcon from '@mui/icons-material/Apps';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';


function OrderProgress({orderStatus, order}) {
  {(() => {
    switch (orderStatus) {
        case 0:
            return 0
        case 1:
            return 1
        case 2:
            return 2
        case 3:
            return 3
      default:
        return null
    }
  })()}  

  return (
    <>
    <div className='orderProgress'>
      <div className="statusTitle">
        <Typography variant="h5" >Status</Typography>
        <div className='date'>
          <p className='captionStatusDetails'>Expected arrival date: {order.order_status == "completed" ? order.order_status : null} </p>
          
          <p className='captionStatusDetails'>Container Number: {order.container_number}</p>
          
          <p className='captionStatusDetails'>Documents Tracking: {order.shipment_carrier} {order.tracking_number}</p>
        </div>
      </div>

            {/* <Timeline position="right">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary"><AppsIcon sx={{ fontSize: "15px" }}/></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Registering</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot color="primary"><AssignmentTurnedInIcon sx={{ fontSize: "15px" }}/></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Approved</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot color="primary"><DirectionsBoatIcon sx={{ fontSize: "15px" }}/></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Shipped</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot color="primary"><CheckCircleIcon sx={{ fontSize: "15px" }}/></TimelineDot>
        </TimelineSeparator>
        <TimelineContent>Complete</TimelineContent>
      </TimelineItem>
    </Timeline> */}



        {/* <div className='statusGraphicContainer'>
          <div className='track'>
            <ul id="progress" className='text-center'>
              <li className='active'></li>
              <li className='active'></li>
              <li className='active'></li>
              <li className='active'></li> 
            </ul>
          </div>
          <div className='lists'>
          <div className='list'>
            <AppsIcon/>
            <p className='captionStatus'><b>Registering Order</b></p>
            <p className='captionUpdate'>The order has been registered</p>
          </div>
          <div className='list'>
            <AssignmentTurnedInIcon/>
            <p className='captionStatus'><b>Approved for Shipping</b></p>
            <p className='captionUpdate'>Order is waiting to be shipped</p>
          </div>
          <div className='list'>
            <DirectionsBoatIcon/>
            <p className='captionStatus'><b>Shipped</b></p>
            <p className='captionUpdate'>Order has successfully shipped</p>
          </div>
          <div className='list'>
            <CheckCircleIcon/>
            <p className='captionStatus'><b>Complete</b></p>
            <p className='captionUpdate'>Order has arrived and is complete</p>
          </div>
          </div>
        </div> */}


      {/* <div className='list'>
            <AppsIcon/>
            <p className='captionStatus'><b>Registering Order</b></p>
            <p className='captionUpdate'>The order has been registered</p>
          </div>
          <div className='list'>
            <AssignmentTurnedInIcon/>
            <p className='captionStatus'><b>Approved for Shipping</b></p>
            <p className='captionUpdate'>Order is waiting to be shipped</p>
          </div>
          <div className='list'>
            <DirectionsBoatIcon/>
            <p className='captionStatus'><b>Shipped</b></p>
            <p className='captionUpdate'>Order has successfully shipped</p>
          </div>
          <div className='list'>
            <CheckCircleIcon/>
            <p className='captionStatus'><b>Complete</b></p>
            <p className='captionUpdate'>Order has arrived and is complete</p>
          </div> */}

      <Timeline position="right" style={{ flex: 0.1, width: "250px" }} >
        <TimelineItem style={{paddingLeft: '0px'}}>

          <TimelineSeparator sx={{ height: "100px" }} >
            <TimelineDot style={{backgroundColor: '#026BFB'}}><AppsIcon sx={{ fontSize: "22px", marginTop: "0" }}/></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineOppositeContent style={{  paddingRight: '0px', fontSize: '16px' }}>
            
            <Typography style={{ float:"left", fontSize: '16px', paddingTop: '0px', }}> Registering Order</Typography>
            <Typography style={{ float:"left", fontSize: '14px', }} color="text.secondary">Order has been registered</Typography>
            </TimelineOppositeContent>
        </TimelineItem>

        <TimelineItem style={{paddingLeft: '0px'}}>
          <TimelineSeparator sx={{ height: "100px" }}>
          <TimelineDot style={{backgroundColor: '#026BFB'}}><AssignmentTurnedInIcon sx={{ fontSize: "22px" }}/></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineOppositeContent style={{  paddingRight: '0px', fontSize: '16px' }}>
          <Typography style={{ float:"left", fontSize: '16px', paddingTop: '0px', }}>Order Approved</Typography>
          <Typography style={{ float:"left", fontSize: '14px', }} color="text.secondary">Waiting to be shipped</Typography>
          </TimelineOppositeContent>
        </TimelineItem>

        <TimelineItem style={{paddingLeft: '0px'}}>
          <TimelineSeparator sx={{ height: "100px" }}>
          <TimelineDot style={{backgroundColor: '#026BFB'}}><AssignmentTurnedInIcon sx={{ fontSize: "22px" }}/></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineOppositeContent style={{  paddingRight: '0px', fontSize: '16px' }}>
          <Typography style={{ float:"left", fontSize: '16px', paddingTop: '0px', }}>Shipped</Typography>
          <br/>
          <Typography style={{ float:"left", fontSize: '14px', }} color="text.secondary">Order in transit</Typography>
          </TimelineOppositeContent>
        </TimelineItem>

        <TimelineItem style={{paddingLeft: '0px'}}>
          <TimelineSeparator sx={{ height: "100px" }}>
          <TimelineDot style={{backgroundColor: '#026BFB'}}><AssignmentTurnedInIcon sx={{ fontSize: "22px" }}/></TimelineDot>
            
          </TimelineSeparator>
          <TimelineOppositeContent style={{  paddingRight: '0px', fontSize: '16px' }}>
          <Typography style={{ float:"left", fontSize: '16px', paddingTop: '0px', }}>Arrived</Typography>
          <br/>
          <Typography style={{ float:"left", fontSize: '14px', }} color="text.secondary">Order complete</Typography>
          </TimelineOppositeContent>
        </TimelineItem>




        {/* <TimelineItem style={{paddingLeft: '0px'}}>
          <TimelineSeparator sx={{ height: "100px" }}>
          <TimelineDot style={{backgroundColor: '#026BFB'}}><DirectionsBoatIcon sx={{ fontSize: "22px" }}/></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px', fontSize: '16px', paddingTop: '14px', paddingLeft: '10px' }}>Shipped</TimelineOppositeContent>
        </TimelineItem> */}

        {/* <TimelineItem style={{paddingLeft: '0px'}}>
          <TimelineSeparator sx={{ height: "100px" }}>
          <TimelineDot style={{backgroundColor: '#026BFB'}}><CheckCircleIcon sx={{ fontSize: "22px" }} /></TimelineDot>
          </TimelineSeparator>
          <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px', fontSize: '16px', paddingTop: '14px', paddingLeft: '10px'}}>
           Arrived 
          </TimelineOppositeContent>
        </TimelineItem> */}
      </Timeline>


      {/* <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }} 
          align="right"
          variant="body2"
          color="text.secondary"
        >
          9:30 am
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <FastfoodIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Eat
          </Typography>
          <Typography>Because you need strength</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          10:00 am
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Code
          </Typography>
          <Typography>Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <HotelIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Sleep
          </Typography>
          <Typography>Because you need rest</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="secondary">
            <RepeatIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Repeat
          </Typography>
          <Typography>Because this is the life you love!</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline> */}
    </div>

    
    </>
  )
}

export default OrderProgress

      {/* <Timeline position="right">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary"><AppsIcon sx={{ fontSize: "15px" }}/></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Registering</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot color="primary"><AssignmentTurnedInIcon sx={{ fontSize: "15px" }}/></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Approved</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot color="primary"><DirectionsBoatIcon sx={{ fontSize: "15px" }}/></TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Shipped</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
        <TimelineDot color="primary"><CheckCircleIcon sx={{ fontSize: "15px" }}/></TimelineDot>
        </TimelineSeparator>
        <TimelineContent>Complete</TimelineContent>
      </TimelineItem>
    </Timeline> */}