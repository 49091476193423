import React from 'react'
import './OrderActionDocument.scss'
import Item1 from '../../../assets/Document_temp/all_documents.pdf'
import Item2 from '../../../assets/Document_temp/loading_chart.pdf'
import Item3 from '../../../assets/Document_temp/catch_certificate.pdf'

//Material UI Components 
import { Paper, Container, Box, Typography, Stack, TableHead,TableContainer, TableBody, TableRow, TableCell, Table } from '@mui/material'


function OrderActionDocuments({order}) {
  return (
    <div className='documentContainer'>
      {/* <ul>
        <li className='documentLists'><a href={Item1} target="_blank">All Documents</a> Upload Date: </li>
        <li className='documentLists'><a href={Item2} target="_blank">Loading Chart</a></li>
        <li className='documentLists'><a href={Item3} target="_blank">Catch Certificate</a></li>
      </ul> */}

      <TableBody >
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
            <TableCell align="left" style={{ width: "400px", height:"20px"}}>
              <p className='orderCellDocumentKey'><b>Name:</b></p>
            </TableCell>

            <TableCell align="left" style={{ width: "400px", height:"20px"}}>
              <p className='orderCellDocumentKey'><b>Upload Date:</b> </p>
            </TableCell>

            <TableCell align="left" style={{ width: "400px", height:"20px"}}>
              <p className='orderCellDocumentKey'><b>Action:</b></p>
            </TableCell>

          </TableRow>
        
        
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
            <TableCell align="left" style={{ width: "400px"}}>
              <p className='orderCellDocumentValue'><a href={Item1} className="linkDocuments" target="_blank">All Documents</a></p>
            </TableCell>

            <TableCell align="left" style={{ width: "400px"}}>
              <p className='orderCellDocumentValue'>{order.po_date}</p>
            </TableCell>

            <TableCell align="left" style={{ width: "400px"}}>
              <p className='orderCellDocumentValue'><a href="" className="linkDocuments" target="_blank">Click to Create +</a></p>
            </TableCell>

          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
            <TableCell align="left" style={{}}>
              
              <p className='orderCellDocumentValue'><a href={Item2} className="linkDocuments" target="_blank">Loading Chart</a></p>
            </TableCell>

            <TableCell align="left" style={{}}>
              
              <p className='orderCellDocumentValue'>{order.po_date}</p>
            </TableCell>

            <TableCell align="left" style={{}}>
            <p className='orderCellDocumentValue'><a href="" className="linkDocuments" target="_blank">Click to Create +</a></p>

            </TableCell>
          </TableRow>

          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
            <TableCell align="left" style={{}}>
              
              <p className='orderCellDocumentValue'><a href={Item3} className="linkDocuments" target="_blank">Catch Certificate</a></p>
            </TableCell>

            <TableCell align="left" style={{}}>
              <p className='orderCellDocumentValue'>{order.po_date}</p>
            </TableCell>

            <TableCell align="left" style={{}}>
              <p className='orderCellDocumentValue'><a href="" className="linkDocuments" target="_blank">Click to Create +</a></p>
            </TableCell>


          </TableRow>
      </TableBody>
    </div>
  )
}

export default OrderActionDocuments