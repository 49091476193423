import { headers, getToken } from '../../Globals'

export const loadProducts = () => {
    return async dispatch => {
        dispatch({type: "REQUESTING"})
        const resp = await fetch( 'api/products', {
            headers: {
              ...headers,
              ...getToken()
            }
          })

          const data = await resp.json()
    
          dispatch({type: "SET_PRODUCTS", payload: data})
          dispatch({type: "DONE_REQUESTING"})
    }
}