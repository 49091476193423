import React from 'react'
import Grid from '@mui/material/Grid';
import "./OrderActionContainer.scss"


const OrderActionContainer = ({
    classes,
    activeStep,
    handleChange,
    tabs,
    formSubmitted
  }) => {
    const tabWidth = 100 / tabs.length;
    const indicatorLeft = activeStep * tabWidth;
    const indicatorStyle = {
      width: `${tabWidth}%`,
      left: `${indicatorLeft}%`
    }
    return (
        <Grid container className="SOV">
            <div className='orderStagesSOV'>
                <ul className="orderStageMenuSOV">
                {tabs.map((tab, index) => (
                    <li key = {index} onClick={handleChange(index)} className={`${index == activeStep ? "activeStepSOV" : "orderStageItemSOV"}`}>{tab}</li>
                    ))}
                </ul>
            </div>
      </Grid>
      )
}

export default OrderActionContainer