import { Paper } from '@mui/material'
import React from 'react'
import ImageLoading from "../../../assets/img-5.svg"
import './OrderActionMessages.scss'

function OrderActionTasks() {
  return (
    <div className='messageOrder'>
    <Paper elevation={0} sx={{ borderRadius: 0, }}> 
      <h4>Order Tasks</h4>
      <p>Coming Soon!</p>
      <img src={ImageLoading} width="300" height="300"/>        
    </Paper>
    </div>
  )
}

export default OrderActionTasks