const initialState = [];

const contactsReducer = (state=initialState, action) => {
    switch(action.type){
        case "ADD_CONTACT":
            return [...state, action.payload]
        case "ADD_CONTACT_CSV":
            return [...state, action.payload]    
        case "DELETE_CONTACT":
            const id = action.payload
            return [...state.filter((contact) => contact.id !== id)]
        
        case "SET_CONTACTS":
            return action.payload
        default: 
        return state;
    }
}

export default contactsReducer;