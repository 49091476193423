import React from 'react'
import './DashboardWidgets.scss'
import SetMealIcon from '@mui/icons-material/SetMeal';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';


function DashboardWidgets({type}) {
    let data;

  //temporary
  
  const diff = 20;

  switch (type) {
    case "active_orders":
      data = {
        title: "Active Orders",
        isMoney: false,
        link: "View All",
        navigation: "/orders",
        amount: 17,
        icon: (
          <SetMealIcon
            className="icon"
            style={{
              color: "#026BFB",
              backgroundColor: "rgba(235, 234, 234, 0.75)",
            }}
          />
        ),
      };
      break;
    case "completed_orders":
      data = {
        title: "Completed Orders",
        isMoney: false,
        link: "View All",
        navigation: "/orders",
        amount: 24,
        icon: (
          <SummarizeIcon
            className="icon"
            style={{
              backgroundColor: "rgba(235, 234, 234,  0.75)",
              color: "#026BFB",
            }}
          />
        ),
      };
      break;
    case "inquiries":
      data = {
        title: "Inquiries",
        isMoney: false,
        link: "View All",
        navigation: "/inquiries",
        amount: 87,
        icon: (
          <SummarizeIcon
            className="icon"
            style={{ backgroundColor: "rgba(235, 234, 234,  0.75)", color: "#026BFB" }}
          />
        ),
      };
      break;
    case "contact":
      data = {
        title: "Contacts",
        isMoney: false,
        link: "View All",
        navigation: "/contacts",
        amount: 103,
        icon: (
          <ContactPageIcon
            className="icon"
            style={{
              backgroundColor: "rgba(235, 234, 234,  0.75)",
              color: "#026BFB",
            }}
          />
        ),
      };
      break;
      case "product":
      data = {
        title: "Products",
        isMoney: false,
        link: "View All",
        navigation: "/products",
        amount: 103,
        icon: (
          <ContactPageIcon
            className="icon"
            style={{
              backgroundColor: "rgba(235, 234, 234,  0.75)",
              color: "#026BFB",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className='widget'>

    <div className='widgetTitle'>{data.title} <Link to={`${data.navigation}`} style={{ textDecoration: "none" }}><AddCircleIcon className="addIcon"/></Link></div>
    <div><p className='widgetCount'><b>{data.amount}</b></p> </div>
    <div className='widgetLinkDiv'><Link to={`${data.navigation}`} style={{ textDecoration: "none" }}><span className = 'widgetLink ' style={{ textDecoration: "none" }}>{data.link}</span></Link></div>
    </div>
  )
}

export default DashboardWidgets