import React from 'react'
import { Paper, Container, Box, Typography, Stack, TableContainer, TextField } from '@mui/material'
import './NewOrderStep2.scss'


function NewOrderStep2({valueProduct, handleFormData, newOrder}) {

  function handleSpecificationChange(e){
    handleFormData(e)
  }





  return (
    <>
    <div className="stepTwo">

        <Typography variant="subtitle2"><b>Selected Product:</b> {valueProduct? valueProduct.name: "None"}</Typography> 

        <br/>

        <Typography variant="subtitle2"><b>Product Specification</b></Typography> 
        <TextField id="outlined-basic" label="Size" InputLabelProps={{ shrink: true }} value={newOrder? newOrder.size: "Size"} variant="outlined" size='small' name="size" sx={{m: 1, width: '25ch'}} onChange={(e) => handleSpecificationChange(e)}/>
        <TextField id="outlined-basic" label="Glaze" InputLabelProps={{ shrink: true }} value={newOrder? newOrder.glaze: "Glaze"} variant="outlined" size='small' name="glaze"  sx={{m: 1, width: '25ch'}} onChange={(e) => handleSpecificationChange(e)}/>
        <TextField id="outlined-basic" label="Freeze" InputLabelProps={{ shrink: true }} value={newOrder? newOrder.selected_freeze: "Freeze"} variant="outlined" size='small' name="selected_freeze"  sx={{m: 1, width: '25ch'}} onChange={(e) => handleSpecificationChange(e)}/>
        <TextField id="outlined-basic" label="Price Per Kg" InputLabelProps={{ shrink: true }} value={newOrder? newOrder.price_kg: "Price Per Kg"} variant="outlined" size='small' name="price_kg"  sx={{m: 1, width: '25ch'}} onChange={(e) => handleSpecificationChange(e)}/>
        <TextField id="outlined-basic" label="Packing" InputLabelProps={{ shrink: true }} value={newOrder? newOrder.packing: "Packing"} variant="outlined" size='small' name="packing"  sx={{m: 1, width: '25ch'}} onChange={(e) => handleSpecificationChange(e)}/>

    </div>
    </>
  )
}

export default NewOrderStep2